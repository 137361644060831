import { AppProvider } from "@shopify/polaris";
import "@shopify/polaris/build/esm/styles.css";
import en from "@shopify/polaris/locales/en.json";
import React, { useState } from "react";
import CompanyFilters from "./CompanyFilters";
import TableData from "./ContactTable";

const Contacts = () => {
  const [filters, setFilters] = useState([]);
  return (
    <AppProvider i18n={en}>
      {/* Debug each component by uncommenting one at a time */}
      {/* <SearchHead />  */}
      <div style={{ display: "flex", alignItems: "flex-start", gap: "2px" }}>
        <CompanyFilters onFilterChange={setFilters} />
        <TableData filters={filters} setFilters={setFilters} />
      </div>
    </AppProvider>
  );
};

export default Contacts;
