import {
  Badge,
  IndexFilters,
  IndexTable,
  LegacyCard,
  useIndexResourceState,
  useSetIndexFiltersMode,
} from "@shopify/polaris";
import Cookies from "js-cookie";
import { useCallback, useEffect, useMemo, useState } from "react";
import instance from "../../api";

function encodeURIComponentStrict(str) {
  return encodeURIComponent(str).replace(/[!'()*-._~]/g, function (c) {
    return "%" + c.charCodeAt(0).toString(16).toUpperCase();
  });
}

function objectToVoyagerStringFormat(obj) {
  const convert = (val) =>
    Array.isArray(val)
      ? `List(${val.map(convert).join(",")})`
      : val && typeof val === "object"
      ? objectToVoyagerStringFormat(val)
      : encodeURIComponentStrict(val);

  return `(${Object.entries(obj)
    .map(([k, v]) => `${k}:${convert(v)}`)
    .join(",")})`;
}

// URL builder utility
const buildSearchUrl = (params = {}, queryParams = {}) => {
  // Validate and adjust pagination
  if (params.page) {
    params.page = Math.max(1, Math.min(100, parseInt(params.page)));
  }

  if (params.count) {
    params.count = Math.max(10, Math.min(100, parseInt(params.count)));
  }

  // Handle sorting
  if (params.sort) {
    if (Array.isArray(params.sort)) {
      params.sort = params.sort
        .map((s) => `${s.field}:${s.direction}`)
        .join(",");
    }
  }

  const queryParts = [
    ...Object.entries(params).map(([k, v]) => `${k}=${encodeURIComponent(v)}`),
    ...(Object.keys(queryParams).length
      ? [`query=${objectToVoyagerStringFormat(queryParams)}`]
      : []),
  ];

  return queryParts.length ? `?${queryParts.join("&")}` : "";
};

function CompanyTable({ filters, setFilters }) {
  // State management
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    count: 20,
    total: 0,
    lastPage: 1,
  });
  const [queryValue, setQueryValue] = useState("");
  const [sortSelected, setSortSelected] = useState(["website:asc"]);
  const { mode, setMode } = useSetIndexFiltersMode();

  // Fetch companies data
  const fetchCompanies = useCallback(async () => {
    try {
      setLoading(true);

      const searchParams = {
        page: pagination.page,
        count: pagination.count,
        sort: sortSelected.map((sort) => {
          const [field, direction] = sort.split(":");
          return { field, direction };
        }),
      };

      const queryParams = {
        ...(queryValue && { searchTerm: queryValue }),
        ...(filters.length && {
          filters: filters.map((filter) => ({
            type: filter.type,
            values: filter.values.map((value) => ({
              id: value.id,
              text: value.text,
              selectionType: value.excluded ? "EXCLUDED" : "INCLUDED",
            })),
          })),
        }),
      };

      const tokenName =
        process.env.REACT_APP_PR_ACCESS_TOKEN || "LocalAccessToken";
      const token = Cookies.get(tokenName);

      const url = `/api/v1/search/company${buildSearchUrl(
        searchParams,
        queryParams
      )}`;
      console.log(url);

      const data = await instance.get(url, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setCompanies(data.data.data || []);
      setPagination((prev) => ({
        ...prev,
        total: data.meta?.total || 0,
        lastPage: data.meta?.last_page || 1,
      }));
    } catch (error) {
      console.error("Error fetching companies:", error);
      // Handle error state
    } finally {
      setLoading(false);
    }
  }, [pagination.page, pagination.count, sortSelected, queryValue, filters]);

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  // Sort options
  const sortOptions = [
    { label: "Company", value: "company:asc", directionLabel: "A-Z" },
    { label: "Company", value: "company:desc", directionLabel: "Z-A" },
    {
      label: "Employee Count",
      value: "employee_count:asc",
      directionLabel: "Low to High",
    },
    {
      label: "Employee Count",
      value: "employee_count:desc",
      directionLabel: "High to Low",
    },
    {
      label: "Founded Year",
      value: "founded_year:asc",
      directionLabel: "Oldest first",
    },
    {
      label: "Founded Year",
      value: "founded_year:desc",
      directionLabel: "Newest first",
    },
    {
      label: "Revenue",
      value: "annual_revenue:asc",
      directionLabel: "Low to High",
    },
    {
      label: "Revenue",
      value: "annual_revenue:desc",
      directionLabel: "High to Low",
    },
  ];

  // Filter handlers
  const handleFiltersQueryChange = useCallback((value) => {
    setQueryValue(value);
    setPagination((prev) => ({ ...prev, page: 1 })); // Reset to first page on search
  }, []);

  const handleFiltersClearAll = useCallback(() => {
    setFilters([]);
    setQueryValue("");
    setPagination((prev) => ({ ...prev, page: 1 }));
  }, [setFilters]);

  // Transform companies data for display
  const transformedCompanies = useMemo(() => {
    return companies.map((item) => ({
      id: item.id,
      website: (
        <a
          href={item.attributes.website}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800"
        >
          {item.attributes.website}
        </a>
      ),
      company: item.attributes.company,
      industry: <Badge>{item.attributes.industry}</Badge>,
      country: item.attributes.location.country,
      linkedin: item.attributes.company_linkedin_url ? (
        <a
          href={item.attributes.company_linkedin_url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800"
        >
          View Profile
        </a>
      ) : (
        "Not Available"
      ),
    }));
  }, [companies]);

  const resourceName = {
    singular: "company",
    plural: "companies",
  };

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(transformedCompanies);

  const rowMarkup = transformedCompanies.map(
    ({ id, website, company, industry, country, linkedin }, index) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell className="w-[20%]">{website}</IndexTable.Cell>
        <IndexTable.Cell className="w-[25%]">{company}</IndexTable.Cell>
        <IndexTable.Cell className="w-[20%]">{industry}</IndexTable.Cell>
        <IndexTable.Cell className="w-[15%]">{country}</IndexTable.Cell>
        <IndexTable.Cell className="w-[20%]">{linkedin}</IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  return (
    <div className="w-full">
      <LegacyCard className="w-full">
        <IndexFilters
          sortOptions={sortOptions}
          sortSelected={sortSelected}
          queryValue={queryValue}
          queryPlaceholder="Search companies"
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={() => setQueryValue("")}
          onSort={setSortSelected}
          tabs={[{ content: "All", index: 0 }]}
          selected={0}
          onSelect={() => {}}
          filters={[]}
          appliedFilters={[]}
          onClearAll={handleFiltersClearAll}
          mode={mode}
          setMode={setMode}
          loading={loading}
        />
        <div className="overflow-x-auto w-full">
          <IndexTable
            resourceName={resourceName}
            itemCount={transformedCompanies.length}
            selectedItemsCount={
              allResourcesSelected ? "All" : selectedResources.length
            }
            onSelectionChange={handleSelectionChange}
            headings={[
              { title: "Website", width: "20%" },
              { title: "Company", width: "25%" },
              { title: "Industry", width: "20%" },
              { title: "Country", width: "15%" },
              { title: "LinkedIn", width: "20%" },
            ]}
            className="w-full"
            loading={loading}
          >
            {rowMarkup}
          </IndexTable>
        </div>
      </LegacyCard>
    </div>
  );
}

export default CompanyTable;
