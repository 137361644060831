import Cookies from "js-cookie";
import debounce from "lodash/debounce";
import { Search } from "lucide-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import instance from "../../api";

// Constants
const SELECTION_TYPES = {
  INCLUDED: "INCLUDED",
  EXCLUDED: "EXCLUDED",
};

// Helper Functions
const transformApiFiltersToGroups = (apiFilters, type) => {
  return apiFilters
    .map((group) => ({
      title: group.group_name,
      sections: group.filters
        .filter((filter) => filter.filter_type === type)
        .map((filter) => ({
          id: filter.id,
          title: filter.name,
          isSearchable: filter.is_searchable,
          canBeExcluded: filter.allows_exclusion,
          onDemandValues: filter.supports_value_lookup,
          inputType: filter.input_type,
        })),
    }))
    .filter((group) => group.sections.length > 0);
};

const transformSelectedItemsToFilters = (selectedItems) => {
  return Object.entries(selectedItems)
    .map(([filterId, items]) => ({
      type: filterId,
      values: items.map((item) => ({
        id: item.id,
        text: item.name,
        selectionType:
          item.type === "include"
            ? SELECTION_TYPES.INCLUDED
            : SELECTION_TYPES.EXCLUDED,
      })),
    }))
    .filter((filter) => filter.values.length > 0);
};

// Custom Hooks
const useFilterState = (filterType) => {
  const [filterGroups, setFilterGroups] = useState([]);
  const [expandedSections, setExpandedSections] = useState({});
  const [searchTerms, setSearchTerms] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const [sectionResults, setSectionResults] = useState({});
  const [activeFilters, setActiveFilters] = useState({});

  const tokenName = process.env.REACT_APP_PR_ACCESS_TOKEN || "LocalAccessToken";
  const token = Cookies.get(tokenName);

  const filters = useMemo(
    () => transformSelectedItemsToFilters(selectedItems),
    [selectedItems]
  );

  const fetchFilters = useCallback(async () => {
    try {
      const { data } = await instance.get("/api/v1/filters", {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const groups = transformApiFiltersToGroups(data.data, filterType);
      setFilterGroups(groups);

      const initialStates = groups.reduce(
        (acc, group) => {
          group.sections.forEach((section) => {
            acc.expandedSections[section.id] = false;
            acc.searchTerms[section.id] = "";
            acc.selectedItems[section.id] = [];
            acc.activeFilters[section.id] = {
              onDemandValues: section.onDemandValues,
              isSearchable: section.isSearchable,
              canBeExcluded: section.canBeExcluded,
            };
          });
          return acc;
        },
        {
          expandedSections: {},
          searchTerms: {},
          selectedItems: {},
          activeFilters: {},
        }
      );

      setExpandedSections(initialStates.expandedSections);
      setSearchTerms(initialStates.searchTerms);
      setSelectedItems(initialStates.selectedItems);
      setActiveFilters(initialStates.activeFilters);
    } catch (error) {
      console.error("Error fetching filters:", error);
    }
  }, [filterType, token]);

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  return {
    filterGroups,
    expandedSections,
    setExpandedSections,
    searchTerms,
    setSearchTerms,
    selectedItems,
    setSelectedItems,
    sectionResults,
    setSectionResults,
    activeFilters,
    filters,
  };
};

const useFilterValueFetcher = (token, activeFilters, setSectionResults) => {
  const fetchFilterValues = useCallback(
    async (sectionId, searchTerm = "") => {
      const filterConfig = activeFilters[sectionId];
      if (!filterConfig) return;

      if (filterConfig.isSearchable && !searchTerm) {
        setSectionResults((prev) => ({ ...prev, [sectionId]: [] }));
        return;
      }

      try {
        const queryParams = {
          filter: sectionId,
          page: "1",
          count: "10",
          ...(filterConfig.isSearchable && searchTerm && { q: searchTerm }),
        };

        const { data } = await instance.get("/api/v1/filters/search", {
          params: queryParams,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        setSectionResults((prev) => ({
          ...prev,
          [sectionId]: data.data,
        }));
      } catch (error) {
        console.error(`Error fetching results for ${sectionId}:`, error);
      }
    },
    [setSectionResults, activeFilters, token]
  );

  return useMemo(() => debounce(fetchFilterValues, 500), [fetchFilterValues]);
};

// Components
const IconButton = ({ children, onClick, className }) => (
  <button
    onClick={onClick}
    className={`p-1 rounded-full hover:bg-gray-100 ${className}`}
  >
    {children}
  </button>
);

const FilterTag = ({ item, type, onRemove }) => (
  <span
    className={`px-2 py-1 rounded-md text-sm flex items-center gap-1`}
    style={{
      backgroundColor: type === "include" ? "#CDFEE1" : "#FEE9E8",
      color: type === "include" ? "#0C5132" : "#8E1F0B",
    }}
  >
    {item.name}
    <IconButton onClick={onRemove}>
      <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
        <path
          fillRule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg>
    </IconButton>
  </span>
);

const SectionHeader = ({ title, expanded, onClick }) => (
  <button
    onClick={onClick}
    className="w-full flex justify-between items-center py-3 px-4 hover:bg-gray-50"
  >
    <span className="text-gray-700">{title}</span>
    <span className="text-gray-400">
      {expanded ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6 10C6 9.58579 6.33579 9.25 6.75 9.25H13.25C13.6642 9.25 14 9.58579 14 10C14 10.4142 13.6642 10.75 13.25 10.75H6.75C6.33579 10.75 6 10.4142 6 10Z"
            fill="#8A8A8A"
          />
        </svg> // Custom SVG for minus
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M10.75 6.75C10.75 6.33579 10.4142 6 10 6C9.58579 6 9.25 6.33579 9.25 6.75L9.25 9.25H6.75C6.33579 9.25 6 9.58579 6 10C6 10.4142 6.33579 10.75 6.75 10.75H9.25L9.25 13.25C9.25 13.6642 9.58579 14 10 14C10.4142 14 10.75 13.6642 10.75 13.25V10.75H13.25C13.6642 10.75 14 10.4142 14 10C14 9.58579 13.6642 9.25 13.25 9.25H10.75V6.75Z"
            fill="#8A8A8A"
          />
        </svg> // Custom SVG for plus
      )}
    </span>
  </button>
);

const FilterItem = ({
  item,
  sectionId,
  onInclude,
  onExclude,
  canBeExcluded,
}) => (
  <div className="flex justify-between items-center py-2 px-4 hover:bg-gray-50">
    <span className="text-sm text-gray-900">{item.name}</span>
    <div className="space-x-2">
      {canBeExcluded ? (
        <>
          <button
            className="text-sm text-green-800 hover:text-green-900"
            onClick={() => onInclude(sectionId, item)}
          >
            Include
          </button>
          <button
            className="text-sm text-red-800 hover:text-red-900"
            onClick={() => onExclude(sectionId, item)}
          >
            Exclude
          </button>
        </>
      ) : (
        <button
          className="text-sm text-gray-700 hover:text-gray-900"
          onClick={() => onInclude(sectionId, item)}
        >
          Select
        </button>
      )}
    </div>
  </div>
);

const GroupHeader = ({ title }) => (
  <div className="px-4 py-3 bg-stone-100">
    <h2 className="font-semibold text-sm font-medium text-gray-600">{title}</h2>
  </div>
);

const NavigationLink = ({ to, isActive, icon, label }) => (
  <Link
    to={to}
    className={`flex items-center space-x-2 px-1 py-3 border-b-2 text-sm font-medium ${
      isActive
        ? "border-blue-600 text-blue-600"
        : "border-transparent text-gray-500 hover:text-gray-700"
    }`}
  >
    {icon}
    <span>{label}</span>
  </Link>
);

const FilterSection = ({
  section,
  isExpanded,
  searchTerm,
  selected,
  results,
  onToggle,
  onSearchChange,
  onInclude,
  onExclude,
  onRemove,
  isSearchable,
  inputType,
}) => {
  const renderInput = () => {
    switch (inputType) {
      case "text":
        return (
          <input
            type="text"
            className="w-full px-4 py-2 border rounded-lg text-sm"
            value={searchTerm}
            onChange={(e) => onSearchChange(section.id, e.target.value)}
            placeholder={`Enter ${section.title.toLowerCase()}`}
          />
        );
      case "multi_select":
      case "select":
      case "hierarchical":
        return (
          isSearchable && (
            <div className="relative">
              <Search
                className="absolute left-3 top-2.5 text-gray-400"
                size={18}
              />
              <input
                type="text"
                placeholder={`Search ${section.title.toLowerCase()}`}
                className="w-full pl-10 pr-4 py-2 border rounded-lg text-sm"
                value={searchTerm}
                onChange={(e) => onSearchChange(section.id, e.target.value)}
              />
            </div>
          )
        );
      default:
        return null;
    }
  };
  return (
    <div className="border-b last:border-b-0">
      <SectionHeader
        title={section.title}
        expanded={isExpanded}
        onClick={() => onToggle(section.id)}
      />

      {isExpanded && (
        <div className="py-2">
          {selected.length > 0 && (
            <div className="flex flex-wrap gap-2 px-4 mb-2">
              {selected.map((item) => (
                <FilterTag
                  key={`${item.type}-${item.id}`}
                  item={item}
                  type={item.type}
                  onRemove={() => onRemove(section.id, item.id)}
                />
              ))}
            </div>
          )}

          <div className="px-4">{renderInput()}</div>

          <div className="mt-2 max-h-64 overflow-y-auto">
            {results
              .filter((item) => !selected.some((s) => s.id === item.id))
              .map((item) => (
                <FilterItem
                  key={item.id}
                  item={item}
                  sectionId={section.id}
                  onInclude={onInclude}
                  onExclude={onExclude}
                  canBeExcluded={section.canBeExcluded}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

const CompanyFilters = ({ onFilterChange }) => {
  const location = useLocation();
  const isPeoplePage = location.pathname === "/search/contacts";
  const tokenName = process.env.REACT_APP_PR_ACCESS_TOKEN || "LocalAccessToken";
  const token = Cookies.get(tokenName);

  const {
    filterGroups,
    expandedSections,
    setExpandedSections,
    searchTerms,
    setSearchTerms,
    selectedItems,
    setSelectedItems,
    sectionResults,
    setSectionResults,
    activeFilters,
    filters,
  } = useFilterState(isPeoplePage ? "contact" : "company");

  const debouncedFetchFilterValues = useFilterValueFetcher(
    token,
    activeFilters,
    setSectionResults
  );

  // Notify parent component when filters change
  useEffect(() => {
    onFilterChange?.(filters);
  }, [filters, onFilterChange]);

  const handleSectionToggle = useCallback(
    (sectionId) => {
      setExpandedSections((prev) => {
        const newState = { ...prev, [sectionId]: !prev[sectionId] };
        if (newState[sectionId] && !activeFilters[sectionId]?.isSearchable) {
          debouncedFetchFilterValues(sectionId, "");
        }
        return newState;
      });
    },
    [setExpandedSections, activeFilters, debouncedFetchFilterValues]
  );

  const handleSearchChange = useCallback(
    (sectionId, value) => {
      setSearchTerms((prev) => ({ ...prev, [sectionId]: value }));
      if (value || !activeFilters[sectionId]?.isSearchable) {
        debouncedFetchFilterValues(sectionId, value);
      }
    },
    [setSearchTerms, activeFilters, debouncedFetchFilterValues]
  );

  const handleInclude = useCallback(
    (sectionId, item) => {
      setSelectedItems((prev) => ({
        ...prev,
        [sectionId]: [...(prev[sectionId] || []), { ...item, type: "include" }],
      }));
    },
    [setSelectedItems]
  );

  const handleExclude = useCallback(
    (sectionId, item) => {
      setSelectedItems((prev) => ({
        ...prev,
        [sectionId]: [...(prev[sectionId] || []), { ...item, type: "exclude" }],
      }));
    },
    [setSelectedItems]
  );

  const handleRemove = useCallback(
    (sectionId, itemId) => {
      setSelectedItems((prev) => ({
        ...prev,
        [sectionId]: prev[sectionId].filter((item) => item.id !== itemId),
      }));
    },
    [setSelectedItems]
  );

  return (
    <div className="w-[360px] bg-white border rounded-lg shadow-sm overflow-auto">
      <header className="flex items-center px-4 py-1 bg-white border-b border-gray-200">
        <nav className="flex space-x-6">
          <NavigationLink
            to="/search/contacts"
            isActive={isPeoplePage}
            icon={<PeopleIcon active={isPeoplePage} />}
            label="People"
          />
          <NavigationLink
            to="/search/companies"
            isActive={!isPeoplePage}
            icon={<CompanyIcon active={!isPeoplePage} />}
            label="Companies"
          />
        </nav>
      </header>

      <div className="pb-4">
        {filterGroups.map((group) => (
          <div key={group.title}>
            <GroupHeader title={group.title} />
            {group.sections.map((section) => {
              console.log(section);
              return (
                <FilterSection
                  key={section.id}
                  section={section}
                  isExpanded={expandedSections[section.id]}
                  searchTerm={searchTerms[section.id]}
                  selected={selectedItems[section.id] || []}
                  results={sectionResults[section.id] || []}
                  onToggle={() => handleSectionToggle(section.id)}
                  onSearchChange={handleSearchChange}
                  onInclude={handleInclude}
                  onExclude={handleExclude}
                  onRemove={handleRemove}
                  isSearchable={activeFilters[section.id]?.isSearchable}
                  inputType={section.inputType}
                />
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

// Icon Components
const PeopleIcon = ({ active }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3.6001C9.68038 3.6001 7.79998 5.4805 7.79998 7.8001C7.79998 10.1197 9.68038 12.0001 12 12.0001C14.3196 12.0001 16.2 10.1197 16.2 7.8001C16.2 5.4805 14.3196 3.6001 12 3.6001ZM9.59998 7.8001C9.59998 6.47461 10.6745 5.4001 12 5.4001C13.3255 5.4001 14.4 6.47461 14.4 7.8001C14.4 9.12558 13.3255 10.2001 12 10.2001C10.6745 10.2001 9.59998 9.12558 9.59998 7.8001Z"
      fill={active ? "#005BD3" : "#4A4A4A"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5807 17.0722C17.253 14.6823 14.7339 13.2001 12 13.2001C9.266 13.2001 6.74694 14.6823 5.41921 17.0722L4.89522 18.0154C4.30084 19.0853 5.07447 20.4001 6.29838 20.4001H17.7015C18.9255 20.4001 19.6991 19.0853 19.1047 18.0154L18.5807 17.0722ZM6.9927 17.9464C8.00296 16.1279 9.9197 15.0001 12 15.0001C14.0802 15.0001 15.997 16.1279 17.0072 17.9464L17.3704 18.6001H6.62953L6.9927 17.9464Z"
      fill={active ? "#005BD3" : "#4A4A4A"}
    />
  </svg>
);

const CompanyIcon = ({ active }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M8.1002 10.8C7.60314 10.8 7.2002 11.2029 7.2002 11.7C7.2002 12.197 7.60314 12.6 8.1002 12.6H8.7002C9.19725 12.6 9.6002 12.197 9.6002 11.7C9.6002 11.2029 9.19725 10.8 8.7002 10.8H8.1002Z"
      fill={active ? "#005BD3" : "#303030"}
    />
    <path
      d="M10.5002 11.7C10.5002 11.2029 10.9031 10.8 11.4002 10.8H12.0002C12.4973 10.8 12.9002 11.2029 12.9002 11.7C12.9002 12.197 12.4973 12.6 12.0002 12.6H11.4002C10.9031 12.6 10.5002 12.197 10.5002 11.7Z"
      fill={active ? "#005BD3" : "#303030"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.2002 6.29995C4.2002 5.14015 5.1404 4.19995 6.3002 4.19995H13.8002C14.96 4.19995 15.9002 5.14015 15.9002 6.29995V6.89995H17.7002C18.86 6.89995 19.8002 7.84015 19.8002 8.99995V17.7C19.8002 18.8598 18.86 19.8 17.7002 19.8H6.3002C5.1404 19.8 4.2002 18.8597 4.2002 17.7V6.29995ZM9.3002 18H10.8002V16.5C10.8002 16.3343 10.6659 16.2 10.5002 16.2H9.6002C9.43451 16.2 9.3002 16.3343 9.3002 16.5V18ZM12.6002 18V16.5C12.6002 15.3402 11.66 14.4 10.5002 14.4H9.6002C8.4404 14.4 7.5002 15.3402 7.5002 16.5V18H6.3002C6.13451 18 6.0002 17.8656 6.0002 17.7V6.29995C6.0002 6.13427 6.13451 5.99995 6.3002 5.99995H13.8002C13.9659 5.99995 14.1002 6.13427 14.1002 6.29995V17.7C14.1002 17.8656 13.9659 18 13.8002 18H12.6002ZM15.9002 17.7V8.69995H17.7002C17.8659 8.69995 18.0002 8.83427 18.0002 8.99995V17.7C18.0002 17.8656 17.8659 18 17.7002 18H15.8789C15.8929 17.902 15.9002 17.8018 15.9002 17.7Z"
      fill={active ? "#005BD3" : "#303030"}
    />
  </svg>
);

export default CompanyFilters;
