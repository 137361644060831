import {
  IndexFilters,
  IndexTable,
  LegacyCard,
  Text,
  useIndexResourceState,
  useSetIndexFiltersMode,
} from "@shopify/polaris";
import Cookies from "js-cookie";
import { useCallback, useEffect, useMemo, useState } from "react";
import instance from "../../api";

function encodeURIComponentStrict(str) {
  return encodeURIComponent(str).replace(/[!'()*-._~]/g, function (c) {
    return "%" + c.charCodeAt(0).toString(16).toUpperCase();
  });
}

function objectToVoyagerStringFormat(obj) {
  const convert = (val) =>
    Array.isArray(val)
      ? `List(${val.map(convert).join(",")})`
      : val && typeof val === "object"
      ? objectToVoyagerStringFormat(val)
      : encodeURIComponentStrict(val);

  return `(${Object.entries(obj)
    .map(([k, v]) => `${k}:${convert(v)}`)
    .join(",")})`;
}

// URL builder utility
const buildSearchUrl = (params = {}, queryParams = {}) => {
  // Validate and adjust pagination
  if (params.page) {
    params.page = Math.max(1, Math.min(100, parseInt(params.page)));
  }

  if (params.count) {
    params.count = Math.max(10, Math.min(100, parseInt(params.count)));
  }

  // Handle sorting
  if (params.sort) {
    if (Array.isArray(params.sort)) {
      params.sort = params.sort
        .map((s) => `${s.field}:${s.direction}`)
        .join(",");
    }
  }

  const queryParts = [
    ...Object.entries(params).map(([k, v]) => `${k}=${encodeURIComponent(v)}`),
    ...(Object.keys(queryParams).length
      ? [`query=${objectToVoyagerStringFormat(queryParams)}`]
      : []),
  ];

  return queryParts.length ? `?${queryParts.join("&")}` : "";
};

function ContactTable({ filters, setFilters }) {
  // State management
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    count: 20,
    total: 0,
    lastPage: 1,
  });
  const [queryValue, setQueryValue] = useState("");
  const [sortSelected, setSortSelected] = useState(["website:asc"]);
  const { mode, setMode } = useSetIndexFiltersMode();

  // Fetch contacts data
  const fetchContacts = useCallback(async () => {
    try {
      setLoading(true);

      const searchParams = {
        page: pagination.page,
        count: pagination.count,
        sort: sortSelected.map((sort) => {
          const [field, direction] = sort.split(":");
          return { field, direction };
        }),
      };

      const queryParams = {
        ...(queryValue && { searchTerm: queryValue }),
        ...(filters.length && {
          filters: filters.map((filter) => ({
            type: filter.type,
            values: filter.values.map((value) => ({
              id: value.id,
              text: value.text,
              selectionType: value.excluded ? "EXCLUDED" : "INCLUDED",
            })),
          })),
        }),
      };

      const tokenName =
        process.env.REACT_APP_PR_ACCESS_TOKEN || "LocalAccessToken";
      const token = Cookies.get(tokenName);

      const url = `/api/v1/search/contact${buildSearchUrl(
        searchParams,
        queryParams
      )}`;
      console.log(url);

      const data = await instance.get(url, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setContacts(data.data.data || []);
      setPagination((prev) => ({
        ...prev,
        total: data.meta?.total || 0,
        lastPage: data.meta?.last_page || 1,
      }));
    } catch (error) {
      console.error("Error fetching contacts:", error);
    } finally {
      setLoading(false);
    }
  }, [filters, pagination.page, pagination.count, sortSelected, queryValue]);

  useEffect(() => {
    fetchContacts();
  }, [fetchContacts]);

  // Sort options based on backend validation
  const sortOptions = [
    { label: "Last Name", value: "last_name:asc", directionLabel: "A-Z" },
    { label: "Last Name", value: "last_name:desc", directionLabel: "Z-A" },
    { label: "First Name", value: "first_name:asc", directionLabel: "A-Z" },
    { label: "First Name", value: "first_name:desc", directionLabel: "Z-A" },
    { label: "Title", value: "title:asc", directionLabel: "A-Z" },
    { label: "Title", value: "title:desc", directionLabel: "Z-A" },
  ];

  // Handle search query changes
  const handleFiltersQueryChange = useCallback((value) => {
    setQueryValue(value);
    setPagination((prev) => ({ ...prev, page: 1 }));
  }, []);

  const handleFiltersClearAll = useCallback(() => {
    setFilters([]);
    setQueryValue("");
    setPagination((prev) => ({ ...prev, page: 1 }));
  }, [setFilters]);

  // Transform contacts data for display
  const transformedContacts = useMemo(() => {
    return contacts.map((item) => ({
      id: item.id,
      name: (
        <Text as="span" variant="bodyMd" fontWeight="semibold">
          {item.attributes.full_name}
        </Text>
      ),
      title: item.attributes.title,
      company: (
        <Text as="span" variant="bodyMd">
          {item.attributes.company}
        </Text>
      ),
      website: (
        <a
          href={item.attributes.website}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800"
        >
          {item.attributes.website}
        </a>
      ),
      linkedin: item.attributes.linkedin_url ? (
        <a
          href={item.attributes.linkedin_url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800"
        >
          View Profile
        </a>
      ) : (
        "Not Available"
      ),
    }));
  }, [contacts]);

  const resourceName = {
    singular: "contact",
    plural: "contacts",
  };

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(transformedContacts);

  // Pagination info
  const paginationInfo = `${
    pagination.total > 0
      ? `Showing ${(pagination.page - 1) * pagination.count + 1}-${Math.min(
          pagination.page * pagination.count,
          pagination.total
        )} of ${pagination.total}`
      : "No results"
  }`;

  const rowMarkup = transformedContacts.map(
    ({ id, name, title, company, website, linkedin }, index) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell className="w-[20%]">{name}</IndexTable.Cell>
        <IndexTable.Cell className="w-[20%]">{title}</IndexTable.Cell>
        <IndexTable.Cell className="w-[20%]">{company}</IndexTable.Cell>
        <IndexTable.Cell className="w-[20%]">{website}</IndexTable.Cell>
        <IndexTable.Cell className="w-[20%]">{linkedin}</IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  return (
    <div className="w-full">
      <LegacyCard className="w-full">
        <IndexFilters
          sortOptions={sortOptions}
          sortSelected={sortSelected}
          queryValue={queryValue}
          queryPlaceholder="Search contacts"
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={() => setQueryValue("")}
          onSort={setSortSelected}
          tabs={[{ content: "All", index: 0 }]}
          selected={0}
          onSelect={() => {}}
          filters={[]}
          appliedFilters={[]}
          onClearAll={handleFiltersClearAll}
          mode={mode}
          setMode={setMode}
          loading={loading}
        />
        <div className="overflow-x-auto w-full">
          <IndexTable
            resourceName={resourceName}
            itemCount={transformedContacts.length}
            selectedItemsCount={
              allResourcesSelected ? "All" : selectedResources.length
            }
            onSelectionChange={handleSelectionChange}
            headings={[
              { title: "Name", width: "20%" },
              { title: "Title", width: "20%" },
              { title: "Company", width: "20%" },
              { title: "Website", width: "20%" },
              { title: "LinkedIn", width: "20%" },
            ]}
            className="w-full"
            loading={loading}
          >
            {rowMarkup}
          </IndexTable>
        </div>
        <div className="px-4 py-3 text-sm text-gray-600">{paginationInfo}</div>
      </LegacyCard>
    </div>
  );
}

export default ContactTable;
